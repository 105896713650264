import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const imagesQuery = graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-traiteur-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
const Image = () => (
  <StaticQuery
    query={imagesQuery}
    render={ data => <Img fluid={data.placeholderImage.childImageSharp.fluid} /> }
  />
)

export default Image
