import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

import imgBG from "../images/bg-texture.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.div`
  h3 {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 30px 0; 
  }
  li {
    padding: 0 0 4px 0;
    margin: 0; 
    font-size: 14px;
  }
  li a {
    padding: 0;
    font-family: 'Open Sans';
    border-bottom: 0;
    color: #fff;
    text-decoration: none;
  }
  li a:hover {
    color: #00ABD4;
  }
  @media (min-width: 1024px) {
    position: relative;
    margin: 0 auto;
    max-width: 960px;
    padding: 1.45rem 1.0875rem;
    h3 {
      font-size: 16px;
      font-weight: 700;
    }
    li {
      font-size: 16px;
    }
  }
`
const Wrapper = styled.div`
`
const Box = styled.div`
  @media (min-width: 768px) {
    display: flex;
    position: relative;
  }
`
const Column = styled.div`
  @media (min-width: 768px) {
    flex: 1;
  }
`
const Bar = styled.div`
  border-top: 1px solid #333;
  position: relative;
  @media (min-width: 768px) {
    border-top: 1px solid #333;
  }
`
const Left = styled.div`
  padding-bottom: 4px;
  position: absolute;
  top: 10px;
  right: 0;
  @media (min-width: 768px) {
    top: 20px;
  }
`
const Right = styled.div`
  padding-bottom: 4px;
  position: absolute;
  top: 10px;
  left: 0;
  @media (min-width: 768px) {
    top: 20px;
  }
`
const Heading = styled.h3`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
`
const SocialIcon = styled.a`
  padding-right: 10px;
  color: #00ABD4;
  &:hover {
    color: #fff;
  }
`
const Button = styled.a`
  display: inline-block;
  background-color: #fff;
  margin: 40px 0 30px 0;
  padding: 4px 14px;
  color: #060809;
  font-family: 'Oswald';
  font-size: 16px;
  border:2px solid #414141;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #00ABD4;
    border:2px solid #00ABD4;
  }
  @media (min-width: 768px) {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
`
const ButtonFooter = styled.a`
  display: block;
  color: #00abd4;
  font-family: 'Oswald';
  font-size: 16px;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #fff;
  }
  @media (min-width: 1024px) {
    padding: 4px 14px;
  }
`
const Copyright = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
  color: #444;
  font-size: 10px;
  @media (min-width: 1024px) {
    padding-top: 40px;
    text-align: center;
  }
`
const Foot = styled.footer`
  background-color: #060809;
  background-image: url(${imgBG});
  border-top: 1px solid #333;
  color: #fff;
  padding: 60px 30px 30px 30px;
`
const LanguageSelector = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 80px;
  }
  a {
    color: #fff;
    margin-right: 10px;
    border-bottom: 2px solid #fff;
  }
  a:hover {
    border-bottom: 2px solid #dab069;
  }
`
const Socials = ({socials}) => {
  if(!socials) {
    return []
  }
  const items = socials.map((e) => {
    return (
      <SocialIcon css={e.css} href={e.url}>
        <FontAwesomeIcon icon={e.icon}/>
      </SocialIcon>
    )
  })

  return items
}
const UnorderedList = ({items}) => {
  let elements = []
  items.forEach((e) => {
    if(e.local) {
      elements.push(<li key={e.name}>
          <Link to={e.url}>{ e.name }</Link>
        </li>)
    } else {
      elements.push(<li key={e.name}>
          <a href={e.url}>{ e.name }</a>
        </li>)
    }
  })
  return <ul>{elements}</ul>
}
const Footer = (props) => {

	const { call_to_action_label,
          call_to_action_link,
          contact_label, 
          contact_link, 
          global_title,
          service_title,
					global_links,
					service_links,
          socials } = props

  const footCss = props.foot
  const containerCss = props.container
  const wrapperCss = props.wrapper
  const boxCss = props.box
  const columnCss = props.column
  const headingCss = props.heading
  const buttonCss = props.button
  const buttonFooterCss = props.buttonFooter
  const barCss = props.bar
  const leftCss = props.left
  const rightCss = props.right
  const socialIconCss = props.socialIcon
  const languageSelectorCss = props.languageSelector
  const copyrightCss = props.copyright

  return (
    <Foot css={footCss}>
      <Container css={containerCss}>
        <Wrapper css={wrapperCss}>
          <Box css={boxCss}>
            <Column css={columnCss}>
              <Heading css={headingCss}>{RichText.asText(service_title)}</Heading>
              <UnorderedList items={service_links} />
            </Column>
            <Column css={columnCss}>
              <Heading css={headingCss}>{RichText.asText(service_title)}</Heading>
              <UnorderedList items={global_links} />
            </Column>
            <Button css={buttonCss} href={call_to_action_link}>
              { call_to_action_label }
            </Button>
          </Box>

          <Bar css={barCss}>
            <Left css={leftCss}>
              <ButtonFooter css={buttonFooterCss} href={contact_link}>
                { contact_label }
              </ButtonFooter>
            </Left>
            <Right css={rightCss}>
              <Socials socials={socials}/>
            </Right>
          </Bar>

          <div>
            <LanguageSelector css={languageSelectorCss}>
              <Link to="/en/">EN</Link>
              <Link to="/">FR</Link>
            </LanguageSelector>
          </div>
        </Wrapper>
      </Container>
      <Copyright css={copyrightCss}>
        © {new Date().getFullYear()} Lucille's Oyster Dive
      </Copyright>
    </Foot>
  )
}

export default Footer
